<template>
  <v-app class="red">
    <app-bar />

    <v-main class="cyan lighten-5 mt-5">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue';

export default {
  name: 'App',

  components: {
    AppBar
  },

  data: () => ({
    //
  }),
};
</script>
