<template>
    <info-card
        :img="require('@/assets/sedgebrook.jpeg')"
        title="19:00 Evening Reception"
        subtitle="Sedgebrook Hall, NN6 8BD"
    >
      <p>The evening reception is scheduled to start at 7pm to include disco and will finish at midnight. The bar
        will be open until midnight.</p>

      <p>Sedgebrook Hall have held some rooms for our guests at a discounted fixed rate. This offer is inclusive of
        bed and breakfast as we appreciate that people will be travelling some distance. Please contact Sedgebrook Hall
        directly to book these rooms quoting that you will be guests at our wedding. Check in is from 2pm.</p>

        <template v-slot:actions>
            <v-btn
                outlined
                text
                href="https://www.google.com/maps/dir//Sedgebrook+Hall,+Pitsford+Rd,+Chapel+Brampton,+Northampton+NN6+8BD/@52.295108,-0.9228764,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x48770dc960352a0d:0xd9880c29afb2b64e!2m2!1d-0.9207442!2d52.2951179!3e0"
                target="_blank"
            >
                Directions
            </v-btn>
            <v-btn
                outlined
                text
                href="https://www.sedgebrookhall.co.uk/contact"
                target="_blank"
            >
                Contact Sedgebrook Hall
            </v-btn>
        </template>
    </info-card>
</template>

<script>
import InfoCard from '@/components/info/InfoCard.vue'

export default {
    components: {
        InfoCard
    }
}
</script>