let config = {
    rsvp: false,
    msg: 'this is a test message'
}

let localConfig = {}

try {
    let m = require('@/config/config.local.js')
    localConfig = m.default
} catch (e) {
    // Ignore
}
config  = Object.assign(config, localConfig)

export default config
