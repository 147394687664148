import VueRouter from 'vue-router'

import Home from './pages/Home.vue'
import Contact from './pages/Contact.vue'
import Info from './pages/Info.vue'
import Rsvp from './pages/Rsvp.vue'
import Gifts from './pages/Gifts.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/contact', component: Contact },
    { path: '/info', component: Info },
    { path: '/rsvp', component: Rsvp },
    { path: '/gifts', component: Gifts },
]
  
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
  