<template>
  <div>

    <v-app-bar app>
      <v-app-bar-nav-icon  @click="drawer = true" class="hidden-md-and-up" />
      <v-toolbar-title>
            <strong><router-link to="/" tag="span" style="cursor: pointer">Adam Whale &amp; Susan Knights Wedding</router-link></strong>
            <v-divider vertical />
            - Saturday 16th July 2022
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
            <v-btn text v-for="item in filteredMenuItems" :to="item.to" :key="item.title">
                <v-icon left dark>{{ item.icon }}</v-icon>
                {{ item.label }}
            </v-btn>
        </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list nav dense>
        <v-list-item-group active-class="indigo--text text--accent-4">

          <v-list-item v-for="item in filteredMenuItems" :to="item.to" :key="item.title">
            <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
    data () {
        return {
            drawer: false,
            menuItems: [
                { label: 'Home', to: '/', icon: 'mdi-home' },
                { label: 'Info', to: '/info', icon: 'mdi-information' },
                { label: 'RSVP', to: '/rsvp', icon: 'mdi-calendar-check', show: this.$config.rsvp },
                { label: 'Gifts', to: '/gifts', icon: 'mdi-gift' },
                { label: 'Contact', to: '/contact', icon: 'mdi-email' }
            ],
        }
    },
    computed: {
      filteredMenuItems() {
        return this.menuItems.filter((item) => (item.show===undefined)?true:item.show)
      },
    }
}
</script>
