<template>
    <v-container >
        <v-row v-if="false">
            <v-col align="center">
                <h1>Information</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col><ceremony-card /></v-col>
        </v-row>

        <v-row>
            <v-col><meal-card v-if="this.$config.rsvp"/></v-col>
        </v-row>

        <v-row>
            <v-col><reception-card /></v-col>
        </v-row>

    </v-container>
</template>

<script>
import CeremonyCard from '@/components/info/CeremonyCard.vue'
import MealCard from '@/components/info/MealCard.vue'
import ReceptionCard from '@/components/info/ReceptionCard.vue'

export default {
    components: {
        CeremonyCard,
        MealCard,
        ReceptionCard
    }
}
</script>
