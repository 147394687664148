import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '@/App.vue'
import Router from '@/router.js'
import vuetify from '@/plugins/vuetify'
import config from '@/config/config.js'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.prototype.$config = config

new Vue({
  render: h => h(App),
  vuetify,
  router: Router
}).$mount('#app')
