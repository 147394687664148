<template>
    <v-card>
        <v-img
            height="250"
            :src="img"
        ></v-img>

        <v-card-title>{{ title }}</v-card-title>
        <v-card-subtitle>{{ subtitle }}</v-card-subtitle>

        <v-card-text>
           <slot></slot>
        </v-card-text>

        <v-card-actions v-if="hasActions">
            <slot name="actions"></slot>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        img: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'Title'
        },
        subtitle: {
            type: String,
            default: 'Subtitle'
        },
    },
    computed: {
        hasActions() {
            return !!this.$slots.actions
        },
    }
}
</script>
