<template>
    <info-card
        :img="require('@/assets/imc.png')"
        title="12:00 Ceremony"
        subtitle="Irthlingborough Methodist Church, NN9 5TU"
    >
      <p>This will be held at Irthlingborough Methodist Church. The church does not have parking, there is free on
        street parking and we are hoping to arrange the usage of the nearby Junior school car park for the occasion
        (please check again closer to the date for confirmation of this).</p>

        <p>The chapel is warm and modern with a gallery upstairs. It also has disabled toilet/baby
        changing facilities. The free town car park is 6 mins walk away at the cross on church st.</p>

      <p>The church is approx 35 minute drive from Sedgebrook hall. There are alot of road closures around
        Wellingborough currently so we advise using the A45 and using the ditchford/rushden exit. The nearest train
        station to Irthlingborough is Wellingborough.</p>

      <template v-slot:actions>
            <v-btn
                outlined
                text
                href="https://www.google.com/maps/dir//Irthlingborough+Methodist+Church,+College+St,+Irthlingborough,+Northants+NN9+5TU/@52.3261789,-0.6159567,18.06z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4877a2e5c9ad9507:0x41da428f32d6da92!2m2!1d-0.6150519!2d52.3256549!3e0"
                target="_blank"
            >
                Directions
            </v-btn>
        </template>
    </info-card>
</template>

<script>
import InfoCard from '@/components/info/InfoCard.vue'

export default {
    components: {
        InfoCard
    }
}
</script>