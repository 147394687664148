<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-container>
            <v-row>
              <v-col align="center">
                <h1>RSVP</h1>
              </v-col>
            </v-row>

            <v-row v-if="!isSubmit">
              <v-col align="center" class="col-md-10 offset-md-1">
                <p class="text-h5">The wedding breakfast (meal) shortly after the ceremony is invitation only and requires an
                  RSVP. The
                  invitation you have received should make it clear if you need to RSVP.</p>

                <p class="text-h5">The event is family friendly, the invitation extends to current partners and children should
                  you wish to
                  bring
                  them</p>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col align="center" class="col-md-10 offset-md-1">
                <p class="text-h5">Thank you for submitting your RSVP!</p>
                <p>
                  <v-btn @click="reset">Reset</v-btn>
                </p>
              </v-col>
            </v-row>


          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-form v-if="!isSubmit" class="mt-4">
      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-container>

              <v-row>
                <v-col>
                  <h2>Contact Details</h2>
                  <p>Please let us know who you are so that we know that you have responded to the invitation.</p>
                  <p>We ask for contact details so that we can get in touch with additional information or
                    queries closer to the date.</p>
                  <p class="mb-0">If you cannot or do not want to attend, please let us know so that we can update our
                    records and know not chase you.</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="contact.name"
                      label="Your Name"
                      outlined
                      hide-details="auto"
                      prepend-inner-icon="mdi-account"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                      v-model="contact.email"
                      label="Email"
                      outlined
                      hide-details="auto"
                      prepend-inner-icon="mdi-email"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="contact.phone"
                      label="Phone"
                      hide-details="auto"
                      prepend-inner-icon="mdi-phone"
                      outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group class="mt-0" v-model="contact.canAttend">
                    <v-radio label="I will be attending the event" :value="true"/>
                    <v-radio label="I am unable to attend the event" :value="false"/>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row  v-if="!contact.canAttend">
                <v-col>
                  <v-btn @click="submit" dark color="green">Submit</v-btn>
                </v-col>
              </v-row>

            </v-container>

          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="contact.canAttend">
        <v-col>
          <v-card elevation="3">
            <v-container>

              <v-row>
                <v-col>

                  <h2>Guests</h2>
                  <p>Please provide details for each person who will attend.</p>
                  <p class="mb-0">We need this information so that we can plan the seating arrangements and print
                    name cards for each attendee. </p>
                </v-col>
              </v-row>

              <v-row v-for="(attendee, index) in contact.attendees" :key="attendee.id">
                <v-col cols="6">
                  <v-text-field
                      v-model="attendee.name"
                      label="Full Name"
                      outlined
                      hide-details="auto"
                      prepend-inner-icon="mdi-account"
                  />
                </v-col>
                <v-col>
                  <v-select
                      v-model="attendee.age"
                      label="Age"
                      :items="ageOptions"
                      hide-details="auto"
                      prepend-inner-icon="mdi-human-male-female-child"
                      outlined
                  ></v-select>
                </v-col>
                <v-col cols="1" align="center" v-if="index!==0">
                  <!-- <v-btn ><v-icon>mdi-account</v-icon></v-btn> -->
                  <v-btn
                      @click="remove(attendee)"
                      fab small
                      class="mt-2"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn @click="add">Add Another Guest</v-btn>
                </v-col>
              </v-row>
            </v-container>

          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="contact.canAttend">
        <v-col>
          <v-card elevation="3">
            <v-container>

              <v-row>
                <v-col>
                  <h2>Other Information</h2>
                  <p class="mb-0">Please provide any additional information (EG: dietary requirements,
                    special accomodations) that you think we ought to be aware of.</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                      v-model="contact.message"
                      label="Additional Information"
                      hide-details="auto"
                      outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn @click="submit" dark color="green">Submit</v-btn>
                </v-col>
              </v-row>

            </v-container>

          </v-card>
        </v-col>
      </v-row>



    </v-form>
  </v-container>
</template>

<script>
import client from '@/services/client.js'

export default {
  data() {
    return {
      isSubmit: false,
      attendeeId: 0,
      contact: {
        name: null,
        phone: null,
        email: null,
        canAttend: true,
        message: null,
        attendees: [],
      },
      recordPrototype: {
        id: null,
        name: null,
        age: null,
      },
      ageOptions: [
        'Adult (13 or over)',
        'Child (5 - 12)',
        'Young Child (4 or under)',
      ]
    }
  },
  mounted: function () {
    this.add()
    if (!this.$config.rsvp) {
      this.$router.push("/")
    }
  },
  methods: {
    submit: function (event) {
      event.preventDefault()
      this.isSubmit = true
      client.post('rsvp.php', this.contact)
          .then(() => {
          })
          .catch((error) => {
            console.log(error)
            alert('Contact Request failed')
          })
    },
    reset: function () {
      this.contact = {
        name: null,
        phone: null,
        email: null,
        canAttend: true,
        message: null,
        attendees: [],
      }
      this.add()
      this.isSubmit = false
    },
    add: function () {
      const attendee = {...this.recordPrototype}
      attendee.id = ++this.attendeeId
      this.contact.attendees.push(attendee)
    },
    remove: function (item) {
      const index = this.contact.attendees.indexOf(item);
      if (index === -1) {
        return;
      }

      this.contact.attendees.splice(index, 1)
    }
  }
}
</script>
