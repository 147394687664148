<template>
    <info-card
        :img="require('@/assets/sedgebrook-reception.jpeg')"
        title="14:00 Wedding Breakfast"
        subtitle="Sedgebrook Hall, NN6 8BD"
    >
      <p>Sedgebrook Hall is located just north of Northampton and the nearest train station is Northampton. There is
        ample parking on site and disabled access. On arrival there will be a drinks reception and hopefully outdoor
        lawn games for guests whilst formal photography takes place.</p>

      <p>All Guests will need to <router-link to="/rsvp">RSVP</router-link> for the meal. We require the full name
        of each guest to arrange seating and place names, as well as contact details so that we can pre-book menu
        selections closer to the date. You can also let us know of any dietary requirements or allergies that we need
        to be aware of.</p>

      <p>We would like this part of the day to be a family friendly event, so current partners and children are welcome.
        There will be a children's menu option for the meal.</p>

        <template v-slot:actions>
            <v-btn
                outlined
                text
                href="https://www.google.com/maps/dir//Sedgebrook+Hall,+Pitsford+Rd,+Chapel+Brampton,+Northampton+NN6+8BD/@52.295108,-0.9228764,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x48770dc960352a0d:0xd9880c29afb2b64e!2m2!1d-0.9207442!2d52.2951179!3e0"
                target="_blank"
            >
                Directions
            </v-btn>

            <v-btn
                dark depressed
                to="/rsvp"
                color="blue"
            >
                RSVP
            </v-btn>
        </template>
    </info-card>
</template>

<script>
import InfoCard from '@/components/info/InfoCard.vue'

export default {
    components: {
        InfoCard
    }
}
</script>
