<template>
  <v-container>
    <v-form v-if="!isSubmit">

      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-container>

              <v-row>
                <v-col align="center">
                  <h1>Contact Us</h1>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="contact.name"
                      label="Your Name"
                      outlined
                      hide-details="auto"
                      prepend-inner-icon="mdi-account"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                      v-model="contact.email"
                      label="Email"
                      outlined
                      hide-details="auto"
                      prepend-inner-icon="mdi-email"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="contact.phone"
                      label="Phone"
                      hide-details="auto"
                      prepend-inner-icon="mdi-phone"
                      outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                      v-model="contact.message"
                      label="Your Query"
                      hide-details="auto"
                      outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn @click="submit" dark color="green">Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

    </v-form>
    <v-row v-else>
      <v-col>
        <v-card elevation="3">
          <v-container>

            <v-row>
              <v-col align="center">
                <h1>Contact Us</h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="center" class="col-md-10 offset-md-1">
                <p class="text-h5">Thank you for getting in touch</p>
                <p>
                  <v-btn @click="reset">Reset</v-btn>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import client from '@/services/client.js'

export default {
  data() {
    return {
      isSubmit: false,
      contact: {
        name: null,
        phone: null,
        email: null,
        message: null,
      }
    }
  },
  methods: {
    submit: function (event) {
      event.preventDefault()
      this.isSubmit = true
      client.post('contact.php', this.contact)
          .then(() => {
          })
          .catch((error) => {
            console.log(error)
            alert('Contact Request failed')
          })
    },
    reset: function () {
      this.contact = {
        name: null,
        phone: null,
        email: null,
        message: null,
      }
      this.isSubmit = false;
    }
  }
}
</script>
